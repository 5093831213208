/**
* Created by tudou on 2020/4/12 18:38.
*/
<template>
  <div class="base-content">
    <form-frame
      :reset-visible="false"
      :submit-visible="false"
    >
      <div slot="content">
        <query-frame
          :searchVisible="false"
          :add-visible="false"
          :pageVisible="false"
        >
          <!-- 查询结果 开始 -->
          <template slot="table">
            <i-table :columns="columns" :dataSource="dataList">
            </i-table>
          </template>
          <!-- 查询结果 结束 -->
        </query-frame>
        <query-frame
          :searchVisible="false"
          :add-visible="false"
          :pageVisible="false"
        >
          <!-- 查询结果 开始 -->
          <template slot="table">
            <i-table :columns="detailColumns" :dataSource="detailDataList">
            </i-table>
          </template>
          <!-- 查询结果 结束 -->
        </query-frame>
      </div>

    </form-frame>

  </div>
</template>

<script>
import { memberScore } from '@/api/member';
import queryMixin from '@/mixin/queryMixin';
import FormFrame from '@/components/frame/FormFrame.vue';

export default {
  name: 'MemberScore',
  mixins: [queryMixin],
  components: {
    FormFrame
  },
  props: {},
  data() {
    return {
      query: {
        phone: null,
      },
      // 默认展示表格
      columns: [
        {
          title: '车号',
          dataIndex: 'matchSerial',
          key: 'matchSerial',
          ellipsis: true
        },
        {
          title: '总计用时',
          dataIndex: 'totalTime',
          key: 'totalTime'
        },
        {
          title: '圈数',
          dataIndex: 'laps',
          key: 'laps'
        },
      ],
      dataList: [],
      // 默认展示表格
      detailColumns: [
        {
          title: '圈次',
          dataIndex: 'inLap',
          key: 'inLap',
          ellipsis: true
        },
        {
          title: '单圈用时',
          dataIndex: 'signleTime',
          key: 'signleTime'
        },
      ],
      detailDataList: []
    };
  },
  computed: {
    paramObj() {
      return {
        matchSerial:this.$route.params.matchSerial,
        userName:this.$route.params.userName,
        openId:this.$route.params.openId,
      };
    },
  },
  watch: {},
  created() {
    console.log(this.paramObj.matchSerial)
    this.getData();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取会员列表
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;
      this.params.matchSerial = this.paramObj.matchSerial;
      this.params.userName = this.paramObj.userName;
      this.params.openId = this.paramObj.openId;
      this.dataList = [];
      this.detailDataList = [];
      console.log(this.params)
      memberScore(this.params).then((data) => {
        console.log(data)
        // const { totalCount, list } = data;
        // this.page.total = data.length;
        // data.forEach((item) => {
        //   item.key = item.matchSerial
        // });
        this.dataList = [data.total];
        this.detailDataList = data.detail;
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
